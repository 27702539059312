import React from 'react';
import { PageNavigation } from '../../../../../components';

const SectionPageNav = () => {
  return (
    <PageNavigation
      links={[
        'Brand',
        'Component',
        'Content',
        'Identity',
        'Tagging',
        'Themes',
        'Utility',
      ]}
    />
  );
};

export default SectionPageNav;
