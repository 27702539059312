import React from 'react';
import WebTab from './_code-web';
import ReactNativeTab from './_code-reactnative';
import AndroidTab from './_code-android';
import AppleTab from './_code-apple';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../../components';
import pageHeroData from '../../../../data/pages/resources.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Colors" subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Code Variables"
        tierThree="Colors"
      />

      <PlatformTabs
        pageType="resources"
        platforms={{
          web: <WebTab />,
          reactnative: <ReactNativeTab />,
          android: <AndroidTab />,
          apple: <AppleTab />,
        }}
      />
    </PageWithSubNav>
  );
};

export default IndexPage;
