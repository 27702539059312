import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ColorDataTableCode, Section } from '../../../../../components';

const Brand = ({ platform }) => {
  const data = useStaticQuery(graphql`
    query resourcesBrandColorsCode($type: String = "/Brand/") {
      ...colorDataCode
    }
  `);

  return (
    <Section title="Brand">
      <ColorDataTableCode data={data} platform={platform} />
    </Section>
  );
};

export default Brand;
