import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ColorDataTableCode, Section } from '../../../../../components';

const Identity = ({ platform }) => {
  const data = useStaticQuery(graphql`
    query resourcesIdentityColorsCode($type: String = "/Identity/") {
      ...colorDataCode
    }
  `);

  return (
    <Section title="Identity">
      <ColorDataTableCode data={data} platform={platform} />
    </Section>
  );
};

export default Identity;
