import React from 'react';
import Brand from './partials/_Brand';
import Components from './partials/_Components';
import Content from './partials/_Content';
import Identity from './partials/_Identity';
import Tagging from './partials/_Tagging';
import Themes from './partials/_Themes';
import Utility from './partials/_Utility';
import SectionPageNav from './partials/_SectionPageNav';
import { PlatformTab } from '../../../../components';

const AppleTab = () => {
  return (
    <PlatformTab>
      <SectionPageNav />
      <Brand platform="apple" />
      <Components platform="apple" />
      <Content platform="apple" />
      <Identity platform="apple" />
      <Tagging platform="apple" />
      <Themes platform="apple" />
      <Utility platform="apple" />
    </PlatformTab>
  );
};

export default AppleTab;
