import React from 'react';
import Brand from './partials/_Brand';
import Components from './partials/_Components';
import Content from './partials/_Content';
import Identity from './partials/_Identity';
import Tagging from './partials/_Tagging';
import Themes from './partials/_Themes';
import Utility from './partials/_Utility';
import SectionPageNav from './partials/_SectionPageNav';
import { PlatformTab } from '../../../../components';

const WebTab = () => {
  return (
    <PlatformTab>
      <SectionPageNav />
      <Brand platform="web" />
      <Components platform="web" />
      <Content platform="web" />
      <Identity platform="web" />
      <Tagging platform="web" />
      <Themes platform="web" />
      <Utility platform="web" />
    </PlatformTab>
  );
};

export default WebTab;
