import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  ColorDataTableCode,
  Section,
  SectionSubhead,
} from '../../../../../components';

const Themes = ({ platform }) => {
  const data = useStaticQuery(graphql`
    query resourcesThemesColorsCode($type: String = "/Theme|Base|Divider/") {
      ...colorDataCode
    }
  `);
  const backgroundData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Theme'
  );
  const baseData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Base'
  );
  const dividerData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Divider'
  );

  return (
    <Section title="Themes">
      <Section>
        <ColorDataTableCode data={baseData} platform={platform} />
      </Section>

      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={backgroundData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={backgroundData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>

      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={dividerData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={dividerData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>
    </Section>
  );
};

export default Themes;
