import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  ColorDataTableCode,
  Section,
  SectionSubhead,
} from '../../../../../components';

const Content = ({ platform }) => {
  const data = useStaticQuery(graphql`
    query resourcesComponentsColorsCode(
      $type: String = "/Buttons|Interjection|Scrim|Selection|Links/"
    ) {
      ...colorDataCode
    }
  `);
  const buttonsData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Buttons'
  );
  const interjectionsData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Interjection'
  );
  const scrimData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Scrim'
  );
  const selectionData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Selection'
  );
  const linkData = data.allColorsYaml.edges.filter(
    node => node.node.type === 'Links'
  );

  return (
    <Section title="Component">
      <Section>
        <SectionSubhead>Main Colors</SectionSubhead>
        <ColorDataTableCode data={buttonsData} platform={platform} />
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={buttonsData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={buttonsData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>

      <Section>
        <ColorDataTableCode data={interjectionsData} platform={platform} />
      </Section>

      <Section>
        <ColorDataTableCode data={scrimData} platform={platform} />
      </Section>

      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={selectionData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={selectionData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>

      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={linkData}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={linkData}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>
    </Section>
  );
};

export default Content;
