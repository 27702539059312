import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ColorDataTableCode, Section } from '../../../../../components';

const Tagging = ({ platform }) => {
  const data = useStaticQuery(graphql`
    query resourcesTaggingColorsCode($type: String = "/Tagging/") {
      ...colorDataCode
    }
  `);

  return (
    <Section title="Tagging">
      <ColorDataTableCode data={data} platform={platform} />
    </Section>
  );
};

export default Tagging;
