import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  ColorDataTableCode,
  Section,
  SectionSubhead,
} from '../../../../../components';

const Content = ({ platform }) => {
  const data = useStaticQuery(graphql`
    query resourcesContentColorsCode($type: String = "/Content/") {
      ...colorDataCode
    }
  `);

  return (
    <Section title="Content">
      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          data={data}
          platform={platform}
          colorsType="light"
        />
        <SectionSubhead>Dark Environment</SectionSubhead>
        <ColorDataTableCode
          data={data}
          platform={platform}
          env="dark"
          colorsType="dark"
        />
      </Section>
    </Section>
  );
};

export default Content;
